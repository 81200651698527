import React, { useMemo } from "react"
import ClusterAWSMap from "~/components/store-search/aws/cluster/ClusterAWSMap"
import ClusterGoogleMap from "~/components/store-search/google/cluster/ClusterGoogleMap"
import { useSettings } from "~/hooks/useSettings"
import { usePrinticularMapTemporaryCredentials } from "../../../components/store-search/aws/hooks/usePrinticularMapTemporaryCredentials"
import { RemoteStoreApi } from "./Stores"

export interface Coordinates {
  id: number
  latitude: number
  longitude: number
}

interface Props {
  stores: RemoteStoreApi[]
  selectedStore: RemoteStoreApi
  onClickMarker?: (markerId: number) => void
}

const StoresMap: React.FC<Props> = ({
  stores,
  selectedStore,
  onClickMarker,
}) => {
  const { settings } = useSettings()
  const { credentials } = usePrinticularMapTemporaryCredentials()

  const { latitude, longitude } = selectedStore

  const points = useMemo(
    () =>
      stores.map(store => ({
        id: +store.id,
        latitude: +store.latitude,
        longitude: +store.longitude,
      })),
    [stores]
  )

  switch (settings.mapProvider) {
    case "googleMaps":
      return (
        <ClusterGoogleMap
          latitude={+latitude}
          longitude={+longitude}
          markers={points}
          onClickMarker={onClickMarker}
          zoom={15}
        />
      )
    case "amazonLocation":
    default:
      if (credentials) {
        return (
          <ClusterAWSMap
            credentials={credentials}
            latitude={+latitude}
            longitude={+longitude}
            onClickMarker={onClickMarker}
            points={points}
            zoom={15}
          />
        )
      }
      return null
  }
}

export default StoresMap

import { Box, Flex } from "@chakra-ui/layout"
import GoogleMapReact from "google-map-react"
import React, { ReactElement, useMemo, useRef, useState } from "react"
import useSupercluster from "use-supercluster"
import { getGeoJSONFeatures } from "~/services/Utils"
import { Coordinates } from "~/templates/layout/stores/StoresMap"
import StoreMarker from "../../StoreMarker"

interface ClusterMarkerProps {
  children: ReactElement
  lng: number
  lat: number
}

const ClusterMarker: React.FC<ClusterMarkerProps> = ({ children }) => children

interface Props {
  longitude: number
  latitude: number
  zoom: number
  markers: Coordinates[]
  onClickMarker?: (markerId: number) => void
}

const ClusterGoogleMap: React.FC<Props> = ({
  longitude,
  latitude,
  markers,
  onClickMarker,
  zoom,
}) => {
  const mapRef = useRef<any>(!null)
  const points = getGeoJSONFeatures(markers)

  const [bounds, setBounds] = useState<any>(null)
  const [currentZoom, setCurrentZoom] = useState(zoom)

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: currentZoom,
    options: { radius: 75, maxZoom: 20 },
  })

  const clustersThrottle = useMemo(
    () => getClustersThrottle(clusters),
    [clusters]
  )

  const onClickCluster = (cluster: any) => {
    const [longitude, latitude] = cluster.geometry.coordinates
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      20
    )

    mapRef.current.setZoom(expansionZoom)
    mapRef.current.panTo({ lat: latitude, lng: longitude })
  }

  return (
    <Box w="100%" h="100%">
      <GoogleMapReact
        center={{
          lat: +latitude,
          lng: +longitude,
        }}
        zoom={zoom}
        onChange={({ zoom, bounds }) => {
          setCurrentZoom(zoom)
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ])
        }}
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map
        }}
      >
        {clusters.map(cluster => {
          const {
            id: clusterId,
            cluster: isCluster,
            point_count: pointCount,
          } = cluster.properties

          const [longitude, latitude] = cluster.geometry.coordinates

          const color =
            pointCount > Math.max(10, clustersThrottle)
              ? "danger"
              : "primary.800"

          const dimension = `${30 + (pointCount / points.length) * 50}px`

          if (isCluster) {
            return (
              <ClusterMarker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <Flex
                  cursor="pointer"
                  background={color}
                  borderRadius={50}
                  justifyContent="center"
                  alignItems="center"
                  color="#fff"
                  fontWeight="bold"
                  w={dimension}
                  h={dimension}
                  onClick={() => onClickCluster(cluster)}
                  _hover={{
                    opacity: 0.8,
                  }}
                >
                  {pointCount}
                </Flex>
              </ClusterMarker>
            )
          }

          return (
            <StoreMarker
              key={clusterId}
              lat={latitude}
              lng={longitude}
              onClick={() => onClickMarker?.(clusterId)}
            />
          )
        })}
      </GoogleMapReact>
    </Box>
  )
}

export default React.memo(ClusterGoogleMap)

const getClustersThrottle = (clusters: any) => {
  const clustersMarkers = clusters.filter(
    (point: any) => point.properties.cluster
  )

  const clusterMarkerCounts = clustersMarkers.map(
    (a: any) => a.properties.point_count
  )
  const clusterMarkerSum = clusterMarkerCounts.reduce(
    (a: any, b: any) => a + b,
    0
  )

  return clusterMarkerSum / clustersMarkers.length
}

import { LayerProps } from "react-map-gl"
import { config } from "~/@chakra-ui/gatsby-plugin/theme"

const {
  colors: { primary },
} = config

export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: "points",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      primary[300],
      100,
      primary[500],
      750,
      primary[700],
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
}

export const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  source: "points",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    // Must be one these fonts https://docs.aws.amazon.com/location-maps/latest/APIReference/API_GetMapGlyphs.html#API_GetMapGlyphs_RequestSyntax
    "text-font": ["Noto Sans Regular"],
    "text-size": 12,
  },
}

export const unclusteredPointLayer: LayerProps = {
  id: "unclustered-point",
  type: "symbol",
  source: "points",
  filter: ["!", ["has", "point_count"]],
  layout: {
    "icon-image": "marker",
    "icon-size": 0.8,
  },
  paint: {
    "icon-opacity": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      0.6,
      1,
    ],
  },
}

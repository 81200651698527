import { Signer } from "@aws-amplify/core"
import "maplibre-gl/dist/maplibre-gl.css"
import React, { useCallback, useEffect, useRef, useState } from "react"
import ReactMapGL, {
  AttributionControl,
  Layer,
  MapRequest,
  NavigationControl,
  Source,
} from "react-map-gl"
import { TemporaryCredentials } from "~/interfaces/entities/TemporaryCredentials"
import { getGeoJSONCollection } from "~/services/Utils"
import { Coordinates } from "~/templates/layout/stores/StoresMap"
import { useAwsClusterFeatures } from "../hooks/useAwsClusterFeatures"
import "../MapAWS.scss"
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "./ClusterLayers"

interface ViewPort {
  latitude: number
  longitude: number
  zoom: number
}

interface Props {
  credentials: TemporaryCredentials
  latitude: number
  longitude: number
  zoom: number
  points: Coordinates[]
  children?: any
  onClickMarker?: (markerId: number) => void
}

const ClusterMapAWS: React.FC<Props> = ({
  credentials,
  latitude,
  longitude,
  zoom = 13,
  points = [],
  onClickMarker,
  children,
}) => {
  const mapRef = useRef<any>(null!)

  const [viewport, setViewport] = useState<ViewPort>({
    latitude: latitude,
    longitude: longitude,
    zoom,
  })

  const { onHoverMap, onClickMap } = useAwsClusterFeatures(
    mapRef,
    viewport,
    setViewport,
    onClickMarker
  )

  useEffect(() => {
    setViewport(previous => {
      return {
        ...previous,
        latitude,
        longitude,
        zoom,
      }
    })
  }, [latitude, longitude, zoom])

  // See https://docs.aws.amazon.com/location/latest/developerguide/tutorial-maplibre-gl-js.html
  const transformRequest = useCallback(
    (url: string, resourceType: string) => {
      if (resourceType === "Style" && !url.includes("://")) {
        url = `https://maps.geo.${credentials?.region}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`
      }

      if (url.includes("amazonaws.com")) {
        url = Signer.signUrl(url, {
          access_key: credentials?.key,
          secret_key: credentials?.secret,
          session_token: credentials?.sessionToken,
        })
      }
      return { url }
    },
    [credentials]
  )

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="100%"
      onViewportChange={setViewport}
      transformRequest={
        transformRequest as (url?: string, resourceType?: string) => MapRequest
      }
      mapStyle={credentials?.resource}
      scrollZoom={false}
      attributionControl={false}
      interactiveLayerIds={["clusters", "unclustered-point"]}
      onClick={onClickMap}
      onHover={onHoverMap}
      ref={mapRef}
    >
      <Clusters points={points} />
      {children}
      <AttributionControl
        compact={true}
        className="mapboxgl-ctrl-attrib-container"
      />
      <NavigationControl
        style={{
          right: 10,
          bottom: 50,
        }}
        showCompass={false}
        showZoom={true}
      />
    </ReactMapGL>
  )
}

export default React.memo(ClusterMapAWS)

interface StoresLayerProps {
  points: Coordinates[]
}

const Clusters: React.FC<StoresLayerProps> = ({ points }) => {
  const GeoJSON: any = getGeoJSONCollection(points)

  return (
    <Source
      id="points"
      type="geojson"
      data={GeoJSON}
      cluster={true}
      clusterMaxZoom={14}
      clusterRadius={50}
      generateId={true}
    >
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />
    </Source>
  )
}

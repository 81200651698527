import { useCallback, useEffect, useRef } from "react"
import { MapEvent } from "react-map-gl"
import StoreMarker from "~/images/store-marker.svg"

export const useAwsClusterFeatures = (
  mapRef: any,
  viewport: any,
  setViewport: any,
  onClickMarker?: (markerId: number) => void
) => {
  const map = mapRef?.current?.getMap()

  /**
   * Register marker icon for clusters Layers
   */
  useEffect(() => {
    if (map) {
      let img = new Image(27, 41)
      img.onload = () => map.addImage("marker", img)
      img.src = StoreMarker
    }
  }, [map, mapRef])

  const hoveredFeature = useRef<any>(null!)

  /**
   * Reduce marker opacity on mouseenter
   */
  const onEnterFeature = useCallback(
    (feature: any) => {
      hoveredFeature.current = feature

      map.getCanvasContainer().style.cursor = "pointer"
      map.setFeatureState(
        {
          source: "points",
          id: feature.id,
        },
        {
          hover: true,
        }
      )
    },
    [map]
  )

  /**
   * Retablish marker opacity on mouseexit
   */
  const onExitFeature = useCallback(() => {
    if (!hoveredFeature?.current) {
      return
    }

    map.getCanvasContainer().style.cursor = "default"
    map.setFeatureState(
      {
        source: "points",
        id: hoveredFeature.current.id,
      },
      {
        hover: false,
      }
    )
    hoveredFeature.current = null
  }, [map])

  /**
   * Looking for features hovered on the map
   */
  const onHoverMap = useCallback(
    event => {
      if (event.features[0]) {
        onEnterFeature(event.features[0])
      } else {
        onExitFeature()
      }
    },
    [onEnterFeature, onExitFeature]
  )

  /**
   * Looking for features clicked on the map
   */
  const onClickMap = useCallback(
    (event: MapEvent) => {
      const feature = event.features

      if (feature && feature.length > 0) {
        const target = feature[0]
        const layerType = target.layer.id

        switch (layerType) {
          case "clusters": // Zoom on the desired cluster
            const clusterId = target.properties.cluster_id
            const mapboxSource = map.getSource("points")

            mapboxSource.getClusterExpansionZoom(
              clusterId,
              (err: any, zoom: number) => {
                if (err) {
                  return
                }

                setViewport({
                  ...viewport,
                  longitude: target.geometry.coordinates[0],
                  latitude: target.geometry.coordinates[1],
                  zoom,
                })
              }
            )
            break

          case "unclustered-point": // Zoom on the desired marker
            const markerId = target.properties.id

            setViewport({
              ...viewport,
              longitude: target.geometry.coordinates[0],
              latitude: target.geometry.coordinates[1],
              zoom: 15,
            })

            onClickMarker?.(markerId)
            break
        }
      }
    },
    [map, onClickMarker, setViewport, viewport]
  )

  return { onHoverMap, onClickMap }
}
